// styles that are used in tailwind theme but need to be imported directly
module.exports = {
	colors: {
		'lumerin-gray': '#F2F5F9',
		'lumerin-light-gray': '#EAF7FC',
		'lumerin-blue-text': '#0E4353',
		'lumerin-black-text': '#252B34',
		'lumerin-inactive-text': '#384764',
		'lumerin-dark-gray': '#DEE3EA',
		'lumerin-dark-blue': '#0E4353',
		'lumerin-input-gray': '#EDEEF2',
		'lumerin-placeholder-gray': '#C4C4C4',
		'lumerin-helpertext-gray': '#707070',
		'lumerin-aqua': '#11B4BF',
		'lumerin-landing-page': '#027380',
		'lumerin-table-text-color': '#0E4353',
		'lumerin-light-aqua': '#DBECED',
		'lumerin-green': '#399E5A',
		'lumerin-light-green': '#5EF38C',
		'lumerin-polygon': '#8247E5',
	},
};

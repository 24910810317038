import { Text } from './types';

// Form text setup
export const paragraphText: Text = {
	review: '',
	confirm: 'Please review the following information above is correct.',
};

export const buttonText: Text = {
	edit: 'Edit Order',
	review: 'Review Order',
	confirm: 'Confirm Order',
	confirmChanges: 'Confirm changes',
	completed: 'Close',
};
